import { mapGetters, mapActions } from 'vuex'

export default {
  data() {
    return {
      tlSaveBtnHadChanges: false,
    }
  },
  computed: {
    ...mapGetters('autosave', { tlSaveBtnStatus: 'status' }),
    tlSaveBtn() {
      return {
        icon: this.tlSaveBtnIcon,
        text: this.tlSaveBtnText,
        action: () => this.saveNow(), // Don't pass the mouseEvent as argument
        disabled: this.tlSaveBtnDisabled,
        hidden: !this.tlSaveBtnHadChanges,
      }
    },
    tlSaveBtnText() {
      switch (this.tlSaveBtnStatus) {
        case 'saveable':
          return this.$t('generic.saveNow')
        case 'saving':
          return this.$t('generic.saving')
        default:
          return this.$t('generic.saved')
      }
    },
    tlSaveBtnIcon() {
      switch (this.tlSaveBtnStatus) {
        case 'saveable':
          return 'tl-cloud'
        case 'saving':
          return 'tl-cloud-upload'
        default:
          return 'tl-cloud-done'
      }
    },
    tlSaveBtnDisabled() {
      return this.tlSaveBtnStatus != 'saveable'
    },
  },
  mounted() {
    let unwatch = this.$watch('tlSaveBtnStatus', newStatus => {
      if (newStatus === 'saved') return
      this.tlSaveBtnHadChanges = true
      unwatch()
    })
  },
  methods: {
    ...mapActions('autosave', ['saveNow']),
  },
}

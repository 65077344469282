<template>
  <v-autocomplete
    :items="countries"
    :filter="startsWtihFilter"
    :value="value"
    item-value="code"
    item-text="name"
    :label="$t('profile.info.country')"
    :disabled="disabled"
    menu-props="auto"
    append-icon="tl-language"
    hide-details
    @input="$emit('input', $event)"
  />
</template>

<script>
import { getCountriesArr } from '@/services/countries'
const countries = getCountriesArr()

export default {
  props: {
    value: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      countries,
    }
  },
  methods: {
    startsWtihFilter(undefined, queryText, itemText) {
      return itemText.toLowerCase().startsWith(queryText.toLowerCase())
    },
  },
}
</script>

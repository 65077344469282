<template>
  <v-card>
    <v-card-title class="headline grey lighten-2" primary-title>
      Edit custom grading system
    </v-card-title>

    <v-card-text>
      <template v-if="!grades.length">
        <p class="mt-4">Select a system that forms the base for your custom grading system.</p>

        <v-select
          v-model="systemInit"
          :items="systemsInit"
          label="Initialize system"
          hint="Select a system to form a base"
          item-text="name"
          return-object
        >
          <template v-slot:item="{ item }">
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.description }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
        </v-select>
      </template>

      <template v-else>
        <v-row justify="end">
          <v-col cols="6">
            <v-select
              v-model="systemCompare"
              :items="systemsCompare"
              label="System to compare grades to"
              item-text="name"
              return-object
            >
              <template v-slot:item="{ item }">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.description }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
            </v-select>
          </v-col>
        </v-row>

        <v-expansion-panels v-model="expandedGradeIndex" class="mt-6">
          <v-expansion-panel disabled>
            <v-expansion-panel-header>
              <v-row no-gutters>
                <v-col cols="3" class="text-center">Points</v-col>
                <v-col cols="2" class="text-center">Name</v-col>
                <v-col cols="3" class="text-center">Groupname medium</v-col>
                <v-col cols="3" class="text-center">Groupname rough</v-col>
              </v-row>
            </v-expansion-panel-header>
          </v-expansion-panel>
          <v-expansion-panel v-for="grade in gradesSorted" :key="grade.value" :disabled="grade.value == 2">
            <v-expansion-panel-header disable-icon-rotate :class="{ 'pr-12': grade.value == 2 }">
              <template v-slot:default="{ open }">
                <v-expand-transition leave-absolute>
                  <v-row v-if="!open" no-gutters align="center">
                    <v-col cols="3" class="text-center">
                      {{ Math.round(grade.value * 100) }}
                      <small class="caption grey--text" v-if="systemCompare">(≈{{ nameCompare(grade) }})</small>
                    </v-col>
                    <v-col cols="2" class="text-center">{{ nameOrQ(grade) }}</v-col>
                    <v-col cols="3" class="text-center">
                      {{ grade.level > 1 ? '|' : grade.name1 || nameOrQ(grade) }}
                    </v-col>
                    <v-col cols="3" class="text-center">
                      {{ grade.level > 0 ? '|' : grade.name0 || nameOrQ(grade) }}
                    </v-col>
                    <v-col cols="1">
                      <v-btn v-if="gradesSorted.length > 2 && grade.value != 2" icon @click.stop="removeGrade(grade)">
                        <v-icon>tl-delete</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-expand-transition>
              </template>
              <template v-slot:actions>
                <v-icon>tl-edit</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <tl-grade-system-edit-grade
                :grade="grade"
                :grades="grades"
                :system-compare="systemCompare"
                @save="saveEditGrade(grade, $event)"
                @cancel="closePanels"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel @change="updateNewGradeValue">
            <v-expansion-panel-header disable-icon-rotate>
              Add grade
              <template v-slot:actions>
                <v-icon>tl-add</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <tl-grade-system-edit-grade
                :grade="gradeNew"
                :grades="grades"
                :system-compare="systemCompare"
                @save="saveNewGrade"
                @cancel="closePanels"
                is-add
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <h3 class="mt-4 sub-title">About grade grouping</h3>
        <p>
          TopLogger can show graphs and statistics about sensible grade-ranges instead of each individual grade.
        </p>
        <p>
          For example in the the french grading system, medium grouping combines 6a with 6a+ into the group named
          '6a(+)' and 6b with 6b+ combine into the group named '6b(+)'. Rough grouping combines all sixes (6a, 6a+, 6b,
          6b+, 6c and 6c+) into the group named '6'.
        </p>
        <p>
          In the interface above you can define your grade groups by defining a grade to be the first in a range, and
          give it an appropriate name for that group.
        </p>
      </template>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-btn text v-if="grades.length" @click="clear">Clear system</v-btn>
      <v-spacer></v-spacer>
      <v-btn text @click="cancel">Cancel</v-btn>
      <v-btn text color="primary" :disabled="grades.length <= 1" @click="save">Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import tlGradeSystemEditGrade from './tl-grade-system-edit-grade'
import Grade from '@/services/grades/Grade'
import systems from '@/services/grades/systems'

export default {
  components: {
    tlGradeSystemEditGrade,
  },
  props: {
    value: { type: String, default: '' },
    climbType: { type: String, default: 'boulder' },
    gymName: { type: String, default: 'this gym' },
  },
  data: () => ({
    systemInit: null,
    grades: [],
    gradeNew: { name: '', value: 2.5, level: 0 },
    expandedGradeIndex: null,
    systemCompare: null,
  }),
  computed: {
    systemsInit() {
      let options = Object.values(systems).filter(s => s.types.includes(this.climbType))
      options.unshift({
        key: 'custom',
        name: 'None',
        description: 'No existing system looks like ours',
        data: [
          { value: 2, name: '0', level: 0 },
          { value: 2.5, name: 'A', level: 0 },
        ],
      })
      return options
    },
    systemsCompare() {
      return Object.values(systems).filter(s => s.types.includes(this.climbType))
    },
    gradesSorted() {
      return [...this.grades].sort((a, b) => a.value - b.value)
    },
    systemDef() {
      return {
        key: `custom_${this.climbType}`,
        name: 'Custom',
        description: `Custom grade system for ${this.climbType}s at ${this.gymName}`,
        types: [this.climbType],
        data: this.grades,
      }
    },
  },
  mounted() {
    this.systemInit = this.value && JSON.parse(this.value)
  },
  watch: {
    systemInit(newSystem) {
      if (!newSystem) return
      this.grades = [...newSystem.data]
      if (newSystem.key != 'custom') this.systemCompare = newSystem
    },
  },
  methods: {
    nameOrQ(grade) {
      return grade.value == 2 ? '?' : grade.name
    },
    nameCompare(grade) {
      return new Grade(grade.value, this.climbType, this.systemCompare).nameOrQ
    },
    saveEditGrade(gradeOriginal, gradeEdit) {
      Object.assign(gradeOriginal, gradeEdit)
      this.closePanels()
    },
    updateNewGradeValue() {
      let maxVal = Math.max.apply(
        Math,
        this.grades.map(g => g.value)
      )
      this.gradeNew.value = maxVal + 0.5
    },
    saveNewGrade(gradeEdit) {
      this.grades.push(Object.assign({}, gradeEdit))
      this.gradeNew = { name: '', level: 0, value: 2.5 }
      this.updateNewGradeValue()
      this.closePanels()
    },
    removeGrade(grade) {
      const index = this.grades.indexOf(grade)
      if (index > -1) {
        this.grades.splice(index, 1)
      }
    },
    closePanels() {
      this.expandedGradeIndex = null
    },
    save() {
      this.$emit('input', JSON.stringify(this.systemDef))
      this.$emit('close')
    },
    cancel() {
      this.$emit('close')
    },
    async clear() {
      let confirmed = await this.$store.dispatch('dialog/confirm', {
        title: `Clear grading system`,
        text: 'Remove all grades and start over. Are you sure?',
      })
      if (!confirmed) return
      this.systemInit = null
      this.grades = []
    },
  },
}
</script>

<template>
  <v-form ref="form" v-model="formValid" lazy-validation>
    <v-row>
      <v-col cols="6">
        <v-text-field
          v-model="points"
          label="Points"
          type="number"
          :rules="[ruleMinPoints, ruleMaxPoints, ruleUniquePoints]"
          :hint="pointsHint"
          persistent-hint
        />
      </v-col>
      <v-col cols="6">
        <v-text-field v-model="gradeEdit.name" label="Name" :rules="[ruleNameMinLength, ruleNameMaxLength]" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-checkbox
          v-model="groupMedium"
          label="New group from here upon medium grouping"
          color="primary"
          hide-details
        />
      </v-col>
      <v-col cols="12" v-if="gradeEdit.level <= 1">
        <v-text-field
          v-model="gradeEdit.name1"
          :label="`Medium group name (${gradeGroupMedium})`"
          :rules="[ruleNameGroupMaxLength]"
          hide-details
        />
      </v-col>
    </v-row>
    <v-row v-if="gradeEdit.level <= 1">
      <v-col cols="12">
        <v-checkbox v-model="groupRough" label="New group from here upon rough grouping" color="primary" hide-details />
      </v-col>
      <v-col cols="12" v-if="gradeEdit.level == 0">
        <v-text-field
          v-model="gradeEdit.name0"
          :label="`Rough group name (${gradeGroupRough})`"
          :rules="[ruleNameGroupMaxLength]"
          hide-details
        />
      </v-col>
    </v-row>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text @click="cancel">Cancel</v-btn>
      <v-btn text color="primary" @click="save" :disabled="!formValid">{{ isAdd ? 'Add' : 'Save' }}</v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import Grade from '@/services/grades/Grade'

export default {
  props: {
    grade: { type: Object, default: () => ({}) },
    grades: { type: Array, default: () => [] },
    systemCompare: { type: Object, default: () => ({}) },
    isAdd: { type: Boolean, default: false },
  },
  data: () => ({
    gradeEdit: {},
    formValid: true,
  }),
  computed: {
    groupMedium: {
      get() {
        return this.gradeEdit.level <= 1
      },
      set(enabled) {
        if (enabled) {
          if (this.gradeEdit.level == 2) this.gradeEdit.level = 1
        } else {
          this.gradeEdit.level = 2
        }
      },
    },
    groupRough: {
      get() {
        return this.gradeEdit.level == 0
      },
      set(enabled) {
        if (enabled) {
          this.gradeEdit.level = 0
        } else {
          this.gradeEdit.level = 1
        }
      },
    },
    points: {
      get() {
        return Math.round(this.gradeEdit.value * 100)
      },
      set(newVal) {
        this.gradeEdit.value = newVal && Math.round(newVal) / 100
      },
    },
    pointsHint() {
      if (!this.gradeEdit.value || !this.systemCompare) return ''
      const compareGradeName = new Grade(this.gradeEdit.value, null, this.systemCompare).nameOrQ
      return `About ${compareGradeName} in ${this.systemCompare.name} system`
    },
    gradesSorted() {
      let grades = this.grades.filter(g => g != this.grade)
      grades.push(this.gradeEdit)
      return grades.sort((a, b) => a.value - b.value)
    },
    gradeGroupMedium() {
      return this.getGradeGroups(1)
        .find(gg => gg.some(g => g == this.gradeEdit))
        .map(g => g.name)
        .join(', ')
    },
    gradeGroupRough() {
      return this.getGradeGroups(0)
        .find(gg => gg.some(g => g == this.gradeEdit))
        .map(g => g.name)
        .join(', ')
    },
  },
  watch: {
    grade: {
      handler: 'syncGradeEdit',
      immediate: true,
    },
  },
  methods: {
    syncGradeEdit() {
      this.gradeEdit = { ...this.grade }
    },
    getGradeGroups(level) {
      let gradeGroups = []
      this.gradesSorted.forEach(grade => {
        if (!gradeGroups.length || grade.level <= level) {
          gradeGroups.push([])
        }
        gradeGroups[gradeGroups.length - 1].push(grade)
      })
      return gradeGroups
    },
    cancel() {
      this.syncGradeEdit()
      this.$emit('cancel')
    },
    save() {
      if (!this.$refs.form.validate()) return
      this.$emit('save', this.gradeEdit)
    },
    ruleMinPoints(value) {
      return (!!value && value > 200) || 'Should be greater than 200'
    },
    ruleMaxPoints(value) {
      return (!!value && value < 950) || 'Should be less than 950'
    },
    ruleUniquePoints(value) {
      if (value == this.grade.value * 100) return true
      return !this.grades.some(g => g.value * 100 == value) || 'Points should be unique'
    },
    ruleNameMinLength(value) {
      return (value && value.length >= 1) || 'Should be at least 1 character'
    },
    ruleNameMaxLength(value) {
      if (!value) return true
      return value.length <= 5 || 'Should be at most 5 characters'
    },
    ruleNameGroupMaxLength(value) {
      if (!value) return true
      return value.length <= 8 || 'Should be at most 8 characters'
    },
    ruleRequired(value) {
      return !!value || 'Required.'
    },
  },
}
</script>

<template>
  <div>
    <v-select
      :value="value"
      :label="`Grading system ${climbType}s`"
      :items="gradingSystems"
      item-text="name"
      item-value="key"
      hide-details
      dense
      @change="$emit('input', $event)"
    >
      <template v-slot:item="{ item }">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
            <v-list-item-subtitle>{{ item.description }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action v-if="item.name == 'Custom' && customEditable">
            <v-btn icon @click.stop="customEditDialog = true">
              <v-icon>tl-edit</v-icon>
            </v-btn>
          </v-list-item-action>
          <v-list-item-action v-if="item.name == 'Custom' && customEditable">
            <v-btn icon @click.stop="removeCustomSystem">
              <v-icon>tl-delete</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider class="mt-2"></v-divider>
      </template>

      <template v-if="customEditable && !gradingSystemCustom" slot="append-item">
        <v-divider />
        <v-list-item @click.stop="customEditDialog = true">
          <v-list-item-content>
            <v-list-item-title>Add custom system</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon>
              <v-icon>tl-arrow-forward</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </template>
    </v-select>

    <v-dialog v-model="customEditDialog" width="500" persistent>
      <tl-grade-system-edit
        v-if="customEditDialog"
        :value="gym[`grading_system_custom_${climbType}s_json`]"
        :climb-type="climbType"
        :gym-name="gym.name"
        @input="saveCustomSystem"
        @close="customEditDialog = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import systems from '@/services/grades/systems'
import tlGradeSystemEdit from '@/services/grades/tl-grade-system-edit'

export default {
  components: {
    tlGradeSystemEdit,
  },
  props: {
    value: { type: String, default: '' },
    climbType: { type: String, default: 'boulder' },
    prependIcon: { type: Boolean, default: false },
    customEditable: { type: Boolean, default: false },
  },
  data: () => ({
    customEditDialog: false,
  }),
  computed: {
    ...mapState(['gym']),
    gradingSystemCustom() {
      let json_str = this.gym[`grading_system_custom_${this.climbType}s_json`]
      return json_str && JSON.parse(json_str)
    },
    gradingSystems() {
      const systemsFiltered = Object.values(systems).filter(s => s.types.includes(this.climbType))
      if (this.gradingSystemCustom) systemsFiltered.push(this.gradingSystemCustom)
      return systemsFiltered
    },
  },
  methods: {
    saveCustomSystem(newVal) {
      this.gym.$update({ [`grading_system_custom_${this.climbType}s_json`]: newVal })
      if (newVal) this.$emit('input', `custom_${this.climbType}`)
      this.$store.dispatch('updateGradeSystems')
    },
    async removeCustomSystem() {
      let confirmed = await this.$store.dispatch('dialog/confirm', {
        title: `Remove custom grading system`,
        text: 'Are you sure?',
      })
      if (!confirmed) return
      this.saveCustomSystem(null)
      // If the just deleted system was the selected system, change the selection to the first system there is:
      if (this.value == `custom_${this.climbType}`) {
        this.$emit('input', this.gradingSystems[0].key)
      }
    },
  },
}
</script>
